import IdiomaContext from 'idiomaContext';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { mensajeFooter } from 'texto';

export default function Footer(){
    const {idioma} = useContext(IdiomaContext);
    return (
        <footer className="bd-footer py-5 mt-5 bg-light">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-3 mb-3">
                <span
                  className="d-inline-flex align-items-center mb-2 link-dark text-decoration-none"
                  aria-label="Bootstrap"
                >
                  
                  <span className="fs-5">Felipe Gavilán</span>
                </span>
                <ul className="list-unstyled small text-muted">
                  <li className="mb-2">
                    {mensajeFooter(idioma)}
                  </li>
                </ul>
              </div>
              <div className="col-6 col-lg-2 offset-lg-1 mb-3">
                <h5>Links</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <Link className="link" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/gavilanch2"
                    >
                      Twitter (español)
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/gavilanch3"
                    >
                      Twitter (english)
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/channel/UCKBzTmM4xXBp4YjyVuNGa2w"
                    >
                      Youtube (español)
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      className="link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/channel/UCS4yRfjjXhjlbx_NiaJB26g"
                    >
                      Youtube (english)
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
    )
}