const IDIOMA_ESPAÑOL = 'esp';
const IDIOMA_INGLES = 'eng';
const IDIOMA_DESCONOCIDO = 'xxx';

const DESCUENTO_ESPECIAL_ESP = 'BLACKFRIDAY-FELIPE';
const DESCUENTO_ESPECIAL_ENG = 'BLACKFRIDAY-FELIPE';
const FECHA_INICIO_DESCUENTO_ESPECIAL = new Date(2024, 10, 29, 0, 0, 0);
const FECHA_FIN_DESCUENTO_ESPECIAL = new Date(2024, 11, 4, 0, 0, 0);


export {IDIOMA_ESPAÑOL, IDIOMA_INGLES, IDIOMA_DESCONOCIDO, DESCUENTO_ESPECIAL_ESP, 
    DESCUENTO_ESPECIAL_ENG, FECHA_INICIO_DESCUENTO_ESPECIAL,FECHA_FIN_DESCUENTO_ESPECIAL};