import { IDIOMA_ESPAÑOL, IDIOMA_INGLES } from "constantes";
import obtenerLink from "cursos/obtenerLinkConCupones";
import React, { useEffect, useState } from "react"
import obtenerCursosDatos from "./cursos-datos";

export default function MisLinks(){
    
    const [cursosEsp, setCursosEsp] = useState([]);
    const [cursosEng, setCursosEng] = useState([]);

    const [cursosEspConDescuentoEspecial, setCursosEspConDescuentoEspecial] = useState([]);
    const [cursosEngConDescuentoEspecial, setCursosEngConDescuentoEspecial] = useState([]);

    useEffect(() => {
        const cursosArr = obtenerCursosDatos().map(x => 
            {return {
                linkCupon: obtenerLink(x.linkUdemy, x.idioma, false),
                titulo: x.titulo,
                idioma: x.idioma,
                prioridad: x.prioridad
            }});
        
        cursosArr.sort((a,b) => b.prioridad - a.prioridad);

        const cursosArrDescuentoEspecial = obtenerCursosDatos().map(x => 
            {return {
                linkCupon: obtenerLink(x.linkUdemy, x.idioma, true),
                titulo: x.titulo,
                idioma: x.idioma,
                prioridad: x.prioridad
            }});
        
            cursosArrDescuentoEspecial.sort((a,b) => b.prioridad - a.prioridad);

        const cursosEsp = cursosArr.filter(x => x.idioma === IDIOMA_ESPAÑOL);
        const cursosEng = cursosArr.filter(x => x.idioma === IDIOMA_INGLES);
        setCursosEsp(cursosEsp);
        setCursosEng(cursosEng);

        const cursosEspDescuento = cursosArrDescuentoEspecial.filter(x => x.idioma === IDIOMA_ESPAÑOL);
        const cursosEngDescuento = cursosArrDescuentoEspecial.filter(x => x.idioma === IDIOMA_INGLES);
        setCursosEspConDescuentoEspecial(cursosEspDescuento);
        setCursosEngConDescuentoEspecial(cursosEngDescuento);
    }, [])
    
    return (
        <>
        <h2>Cursos ESP - Sin Descuento Especial</h2>
        {cursosEsp.map((curso,indice) => <p key={indice}><b>{curso.titulo}</b>: {curso.linkCupon}</p>)}
        <h2>Cursos ESP - Con Descuento Especial</h2>
        {cursosEspConDescuentoEspecial.map((curso,indice) => <p key={indice}><b>{curso.titulo}</b>: {curso.linkCupon}</p>)}
        <h2>Cursos ENG - Con Descuento Especial</h2>
        {cursosEng.map((curso,indice) => <p key={indice}><b>{curso.titulo}</b>: {curso.linkCupon}</p>)}
        <h2>Cursos ENG - Con Descuento Especial</h2>
        {cursosEngConDescuentoEspecial.map((curso,indice) => <p key={indice}><b>{curso.titulo}</b>: {curso.linkCupon}</p>)}
        </>
    )
}