// @ts-nocheck
import React, { useContext, useEffect } from "react";
import ListadoCursos from "./ListadoCursos";
import obtenerCursosDatos from "../cursos-datos";
import { useParams, useLocation } from "react-router-dom";
import { tituloDescuento } from "../texto";
import IdiomaContext from "../idiomaContext";
import {  IDIOMA_ESPAÑOL, IDIOMA_INGLES } from '../constantes';

export default function CursosConDescuento() {
  const { filtro } = useParams();
  const { idioma, setIdioma } = useContext(IdiomaContext);
  const query = new URLSearchParams(useLocation().search);

  const obtenerTitulo = () => {
    return tituloDescuento(idioma);
  };

  useEffect(() => {

    if (!query.get("idioma")) {
      return;
    }

    const idiomaQS = query.get("idioma");
    if (idiomaQS === IDIOMA_ESPAÑOL) {
      setIdioma(IDIOMA_ESPAÑOL);
    } else if (idiomaQS === IDIOMA_INGLES) {
      setIdioma(IDIOMA_INGLES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h3>{obtenerTitulo()}</h3>
      <ListadoCursos listado={obtenerCursosDatos().reverse()} filtro={filtro} descuento={true} />
    </>
  );
}
