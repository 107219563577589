import Cursos from "cursos/Cursos";
import CursosConDescuento from "cursos/CursosConDescuento";
import LandingPage from "LandingPage";
import MisLinks from "MisLinks";
import RedirectToLandingPage from "RedirectToLandingPage";

const routes = [
    {path: '/cursos/:filtro?', component: Cursos},
    {path: '/cursos-con-descuento/:filtro?', component: CursosConDescuento},
    {path: '/mislinks', component: MisLinks},
    {path: '/', component: LandingPage, exact: true},
    {path: '*', component: RedirectToLandingPage}
]

export default routes;